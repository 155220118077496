// Third party libraries
import { nanoid } from 'nanoid';
import useCollapse from 'react-collapsed';
import { getTruckFeeMin } from '../../util/utils';
import * as immutable from 'object-path-immutable';
// Styles
import styles from './CalculationTabOneDayValues.module.css';
import { ExtraOptionsValues } from '../extra-options-values/Extra-options-values';
import { MiscChechMarksValues } from '../misc-checkmarks-values/Misc-checkmarks-values';
import { AdditionalServicesValues } from '../additional-services-values/Additional-services-values';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import clockIcon from './../../img/clock.png';
import AdjustTime from '../adjust-time/Adjust-time';

export const CalculationTabOneDayValues = ({ rates, formData, setCalculationFormValue, formChange, lastTarget, setLastTarget, resetLastTarget,
  sepCheckboxValueChange, validationError, setValidationError, checkMarkCheckboxValueChange, additionalServicesCheckboxValueChange, disabled }) => {
  const TWO_Trips_Or_OK = useSelector(state => state.calculatedParams.TWO_Trips_Or_OK);
  const [showInputs, setShowInputs] = useState(false);
  const [showInputsUnpacking, setShowInputsUnpacking] = useState(false);

  const onValueChange = (evt) => {
    resetLastTarget();
    formChange(`oneDayPacking.${evt.target.name}`, 'set', evt.target.value)

    if (typeof setValidationError === 'function') {
      if ((evt.target.name === 'moversToDrivesAndUnload') && parseInt(evt.target.value) > parseInt(formData.oneDayPacking.moversToLoad)) {
        setValidationError({ ...validationError, [formData.calculationType]: 'Movers to drive and unload can not be more than Movers to load!' });
      } else
        if ((evt.target.name === 'moversToLoad') && parseInt(evt.target.value) < parseInt(formData.oneDayPacking.moversToDrivesAndUnload)) {
          setValidationError({ ...validationError, [formData.calculationType]: 'Movers to drive and unload can not be more than Movers to load!' });
        } else {
          setValidationError('');
        }
    }
  }

  const twoTripsCheckboxValueChange = (evt) => {
    resetLastTarget();
    const { name, value } = evt.target;
    const newValue = value === 'true' ? false : value === 'false' ? true : value;
    const newState = immutable.set(formData, `oneDayPacking.${name}`, newValue);
    setCalculationFormValue(newState);
    const unpackingRatePath = 'oneDayPacking.twoTripsManuallyInserted';
    const unpackingRateState = immutable.set(newState, unpackingRatePath, true)
    setCalculationFormValue(unpackingRateState)
  };

  useEffect(() => {
    const baseLoadingHours = formData.commonValues.baseLoadingHours;
    const baseUnloadingHours = formData.commonValues.baseUnloadingHours;
    if ((baseUnloadingHours == 0 || baseLoadingHours == 0) && !(baseUnloadingHours == 0 && baseLoadingHours == 0)) {
      formChange(`oneDayPacking.noTwoTrips`, 'set', true);
    } else {
      if (!formData.oneDayPacking.noTwoTrips) {
        formChange(`oneDayPacking.noTwoTrips`, 'set', false);
      }
    }
    if (!formData.oneDayPacking.twoTripsManuallyInserted && baseUnloadingHours != 0 && baseLoadingHours != 0) {
      formChange(`oneDayPacking.noTwoTrips`, 'set', false);
    }
  }, [formData.commonValues.baseUnloadingHours, formData.commonValues.baseLoadingHours]);



  const chargeCheckboxValueChange = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.oneDayPacking.selectedChargesNamesDay1];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`oneDayPacking.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`oneDayPacking.selectedChargesNamesDay1`, 'push', { key: evt.target.name, quantity: 1 });
        break;
      default:
        break;
    }
  }


  const chargeQuantityChange = (evt) => {
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.oneDayPacking.selectedChargesNamesDay1));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`oneDayPacking.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
    }
  }
  const isCuFtDepartment = formData.department?.type === 'cuft';

  const aSQuantityChange = (evt) => {
    setLastTarget(evt.target.name)
    var newSelectedASNames = JSON.parse(JSON.stringify(formData.commonValues.selectedAdditionalServices))
    var index = newSelectedASNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedASNames[index].quantity = Number(evt.target.value)
      return formChange(`commonValues.selectedAdditionalServices`, 'set', newSelectedASNames);
    }
  }

  useEffect(() => {
    if (isCuFtDepartment) {
      formChange(`commonValues.unpackingRate`, 'set', formData.oneDayPacking.rate);
    }
  }, []);


  return (
    <section className={styles.container}>
      <div className={styles.groupInput} style={{ flexDirection: 'column' }}>
        <label className={styles.commonLabel}>
          Date:
          <input
            className={styles.dateInput}
            type='date'
            name={'firstDayDate'}
            value={formData.oneDayPacking.firstDayDate}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
        <label>
          Additional amount:
          <input
            onWheel={(e) => e.target.blur()}
            style={{ width: "60px" }}
            type='number'
            name={'additionalAmount'}
            value={(formData.oneDayPacking) ? formData.oneDayPacking.additionalAmount : ''}
            placeholder={'ex: 2'}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
      </div>
      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", width: '550px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>MOVING DAY</span>
            <img src={clockIcon} onClick={() => setShowInputs(!showInputs)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {(showInputs || formData.commonValues.addTimeFirstDay != 0 || formData.commonValues.removeTimeFirstDay != 0) ? (
            <fieldset className={styles.groupInput}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeFirstDay'}
                  value={formData.commonValues.addTimeFirstDay}
                  labelText={'Add time:'}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeFirstDay'}
                  value={formData.commonValues.removeTimeFirstDay}
                  labelText={'Remove time:'}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          ) : ''}
        </div>
        <div style={{ padding: '5px' }}>
          <div className={styles.groupInput}>
            {rates.length === 0 && <p className={styles.warning}>Select Department!</p>}
            {rates.length > 0 &&
              <label>
                Rate:
                <select
                  className={styles.select}
                  value={formData.oneDayPacking.rate}
                  onChange={(evt) => {
                    resetLastTarget();
                    const newValue = evt.target.value;
                    const newState = immutable.set(formData, 'oneDayPacking.rate', newValue);
                    setCalculationFormValue(newState);
                    if (isCuFtDepartment) {
                      const unpackingRatePath = 'commonValues.unpackingRate';
                      const unpackingRateState = immutable.set(newState, unpackingRatePath, newValue)
                      setCalculationFormValue(unpackingRateState)
                    }
                  }}
                  disabled={disabled}
                >
                  <option value={''} key='none'>None</option>
                  {
                    rates.map((rate, index) => {
                      return (
                        <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                      );
                    })
                  }
                </select>
              </label>
            }
            <div className={styles.vl}></div>
            {isCuFtDepartment &&
              <label style={{ marginLeft: '10px' }}>Type of place
                <select value={formData.commonValues.additionalServicesPlaceType}
                  className={styles.commonInput}
                  onChange={evt => formChange(`commonValues.additionalServicesPlaceType`, 'set', evt.target.value)}
                  disabled={disabled}
                >
                  <option>None</option>
                  {formData.department.additionalServices.placeTypes.length && formData.department.additionalServices.placeTypes.map((placeType, pcftIndex) => {
                    return <option key={pcftIndex}>{placeType.placeTypeName}</option>
                  })}
                </select>
              </label>
            }
            {formData.commonValues?.shuttle &&
              <>
                <label className={styles.shuttleOnDayOne}>
                  Shuttle
                  <input
                    type='checkbox'
                    name={'shuttle'}
                    value={(formData.commonValues?.shuttle) ? formData.commonValues.shuttle : false}
                    checked={(formData.commonValues?.shuttle) ? formData.commonValues.shuttle : false}
                    disabled
                  />
                </label>
                {formData.department?.extraOptions?.shuttle.isCountable &&
                  <label className={styles.inputContainerSh}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityDay1'}
                      value={formData.oneDayPacking.quantityDay1}
                      placeholder={'ex: 2'}
                      onChange={onValueChange}
                      onWheel={(e) => e.target.blur()}
                      disabled={disabled}
                    />
                  </label>
                }
              </>
            }


          </div>
          <fieldset className={styles.groupInput}>
            <div className={styles.divide}>
              <label className={styles.commonLabel}>
                <span>Add Movers:</span>
                <select
                  className={styles.commonInput}
                  name={'addMovers'}
                  value={formData.oneDayPacking.addMovers}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option key='0'>0</option>
                  <option key='1'>1</option>
                  <option key='2'>2</option>
                  <option key='3'>3</option>
                  <option key='4'>4</option>
                  <option key='5'>5</option>
                  <option key='6'>6</option>
                  <option key='7'>7</option>
                  <option key='8'>8</option>
                  <option key='9'>9</option>
                  <option key='10'>10</option>
                </select>
              </label>

              <label className={styles.commonLabel} style={{ marginLeft: "15px" }}>
                <span>Remove Movers:</span>
                <select
                  className={styles.commonInput}
                  name={'removeMovers'}
                  value={formData.oneDayPacking.removeMovers}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option key='0'>0</option>
                  <option key='1'>1</option>
                  <option key='2'>2</option>
                  <option key='3'>3</option>
                  <option key='4'>4</option>
                  <option key='5'>5</option>
                  <option key='6'>6</option>
                  <option key='7'>7</option>
                  <option key='8'>8</option>
                  <option key='9'>9</option>
                  <option key='10'>10</option>
                </select>
              </label>

              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <label className={styles.commonLabel}>
                  <span>Add Trucks:</span>
                  <select
                    className={styles.commonInput1}
                    name={'addTrucks'}
                    value={formData.oneDayPacking.addTrucks}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option key='0'>0</option>
                    <option key='1'>1</option>
                    <option key='2'>2</option>
                    <option key='3'>3</option>
                    <option key='4'>4</option>
                    <option key='5'>5</option>
                    <option key='6'>6</option>
                    <option key='7'>7</option>
                    <option key='8'>8</option>
                    <option key='9'>9</option>
                    <option key='10'>10</option>
                  </select>
                </label>

                <label className={styles.commonLabel} style={{ marginLeft: "15px" }}>
                  <span>Remove Trucks:</span>
                  <select
                    className={styles.commonInput1}
                    name={'removeTrucks'}
                    value={formData.oneDayPacking.removeTrucks}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option key='0'>0</option>
                    <option key='1'>1</option>
                    <option key='2'>2</option>
                    <option key='3'>3</option>
                    <option key='4'>4</option>
                    <option key='5'>5</option>
                    <option key='6'>6</option>
                    <option key='7'>7</option>
                    <option key='8'>8</option>
                    <option key='9'>9</option>
                    <option key='10'>10</option>
                  </select>
                </label>
                {(TWO_Trips_Or_OK === '2 TRIPS' || formData.oneDayPacking.noTwoTrips) ?
                  <label className={styles.commonLabeling} >
                    NO 2 TRIPS
                    <input
                      className={styles.mycheckbox}
                      type='checkbox'
                      name={'noTwoTrips'}
                      checked={formData.oneDayPacking.noTwoTrips}
                      value={formData.oneDayPacking.noTwoTrips}
                      onChange={evt => { twoTripsCheckboxValueChange(evt) }}
                      disabled={disabled || ((formData.commonValues.baseUnloadingHours == 0 || formData.commonValues.baseLoadingHours == 0) &&
                        !(formData.commonValues.baseUnloadingHours == 0 && formData.commonValues.baseLoadingHours == 0))}
                    />
                  </label>
                  : ''}
              </div>
            </div>


            <div className={styles.decorateContainer}>
              <label className={styles.inputContainer}>
                Movers to Load:
                <input
                  type='number'
                  name={'moversToLoad'}
                  value={formData.oneDayPacking?.moversToLoad}
                  placeholder={'ex: 2'}
                  onChange={onValueChange}
                  onWheel={(e) => e.target.blur()}
                  disabled={disabled}
                />
              </label>
              <label className={styles.inputContainer} style={{ marginLeft: '20px' }}>
                Movers to drive and unload:
                <input
                  type='number'
                  name={'moversToDrivesAndUnload'}
                  value={formData.oneDayPacking?.moversToDrivesAndUnload}
                  placeholder={'ex: 2'}
                  onChange={onValueChange}
                  onWheel={(e) => e.target.blur()}
                  disabled={disabled}
                />
              </label>
              {validationError && <div className={styles.error}>{validationError[formData.calculationType]}</div>}
            </div>
          </fieldset>

          {(formData.department && formData.department.extraOptions && formData.department.extraOptions.charges && formData.department.extraOptions.charges.length > 0) &&
            <>
              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChange}
                selectedChargesNames={formData.oneDayPacking.selectedChargesNamesDay1}
                chargeQuantityChange={chargeQuantityChange}
                lastTarget={lastTarget}
                disabled={disabled}
              />
              {/* */}
            </>
          }


          <div>
            {(formData.department && formData.department?.type === 'cuft') && <>
              <hr className={styles.hLine} />
              <AdditionalServicesValues
                additionalServices={(formData.department && formData.department.additionalServices) ? formData.department.additionalServices : []}
                selectedAdditionalServices={formData.commonValues.selectedAdditionalServices}
                additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
                additionalServicesPlaceType={formData.commonValues.additionalServicesPlaceType}
                formChange={formChange}
                aSQuantityChange={aSQuantityChange}
                disabled={disabled}
              />
            </>
            }
          </div>

          <hr className={styles.hLine} />
          <MiscChechMarksValues
            miscCheckMarks={(formData.department && formData.department.miscCheckMarks && formData.department.miscCheckMarks.miscCheckMarks.length) ? formData.department.miscCheckMarks.miscCheckMarks : []}
            selectedMiscCheckMarks={formData.selectedMiscCheckMarks}
            checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
            disabled={disabled}
          />
        </div>

      </div>

      {formData.commonValues.unpacking === 'Separate Day' &&
        <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '550px' }}>
          <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>UNPACKING ON SEPARATE DAY</span>
              <img src={clockIcon} onClick={() => setShowInputsUnpacking(!showInputsUnpacking)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
            </div>
            {showInputsUnpacking && (
              <fieldset className={styles.groupInput}>
                <div className={styles.decorateContainer}>
                  <AdjustTime
                    name={'addTimeUnp'}
                    value={formData.commonValues.addTimeUnp}
                    labelText={'Add time:'}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  />
                  <AdjustTime
                    name={'removeTimeUnp'}
                    value={formData.commonValues.removeTimeUnp}
                    labelText={'Remove time:'}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  />
                </div>
              </fieldset>
            )}
          </div>
          <div style={{ padding: '5px' }}>
            <div className={styles.decorateContainer2}>


              {rates.length === 0 && <p className={styles.warning1}>Select Department!</p>}
              {rates.length > 0 &&
                <label className={styles.decorateContainer1}>
                  Rate:
                  <select
                    className={styles.select}
                    disabled={isCuFtDepartment}
                    value={isCuFtDepartment ? formData.oneDayPacking.rate : formData.commonValues.unpackingRate}
                    onChange={(evt) => {
                      resetLastTarget();
                      formChange('commonValues.unpackingRate', 'set', evt.target.value);

                    }}
                  >
                    <option value={''} key='none'>None</option>
                    {
                      rates.map((rate, index) => {
                        return (
                          <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                        );
                      })
                    }
                  </select>
                </label>
              }

              <fieldset className={styles.groupInput}>
                <label className={styles.checkLabel} style={{ width: "400px", justifyContent: "flex-start" }} >
                  <span className={styles.inputContainer}>Movers to Unpack:</span>
                  <select
                    className={styles.commonInput}
                    name={'moversToUnPackSeparate'}
                    value={(formData.commonValues.moversToUnPackSeparate) ? formData.commonValues.moversToUnPackSeparate : ''}
                    onChange={(evt) => {
                      resetLastTarget();
                      formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                    }

                    }
                  >
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </label>
              </fieldset>





              {formData.commonValues?.shuttle &&
                <div className={styles.groupInputSU}>
                  <label className={styles.shuttleOnDay}>
                    Shuttle on Unpacking Day
                    <input
                      type='checkbox'
                      name={'shuttleOnUnpacking'}
                      value={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                      checked={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                      onChange={sepCheckboxValueChange}
                    />
                  </label>
                  {formData.commonValues.shuttleOnUnpacking &&
                    <label className={styles.inputContainerSh}>
                      Qty:
                      <input
                        type='number'
                        name={'quantityUnpacking'}
                        value={formData.commonValues.quantityUnpacking}
                        placeholder={'ex: 2'}
                        onChange={(evt) => {
                          resetLastTarget();
                          formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                        }}
                        onWheel={(e) => e.target.blur()}
                      />
                    </label>
                  }
                </div>
              }



              {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
                <fieldset className={styles.groupInput}>
                  <label className={styles.inputContainer1} style={{ width: "400px", justifyContent: "flex-start", display: 'block', marginLeft: '20px' }}>
                    Truck fee:
                    <input
                      onWheel={(e) => e.target.blur()}
                      type='number'
                      name={'truckFeeSeparate'}
                      value={(formData._id) ? (formData.oneDayPacking) ? formData.oneDayPacking.truckFeeSeparate : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                      placeholder={'ex: 40'}
                      onChange={onValueChange}
                    />
                  </label>
                </fieldset>
              }
            </div>
          </div>
        </div>}
    </section>

  );
};